












































































































import { MetaInfo } from "vue-meta";
import { Component, Vue } from "vue-property-decorator";

import firebase from "firebase/app";

@Component({
    metaInfo(): MetaInfo {
        firebase.analytics().logEvent("page_view");
        firebase.analytics().logEvent("screen_view", {
            'app_name': process.env.VUE_APP_PROJECT,
            'app_type': process.env.VUE_APP_TYPE,
            'screen_name': 'Contact',
            'app_version': process.env.VUE_APP_VERSION
        });

        return {
            title: 'Contact'
        };
    }
})
export default class Contact extends Vue {
    center = { lat: 21.890546, lng: -102.271374 };
    
    defaultStyle = [
        /** ALL */
        {elementType: 'labels.text.stroke', stylers: [{visibility: 'off'}]},
        /** POI */
        {
            featureType: 'poi',
            elementType: 'all',
            stylers: [{visibility: 'off'}]
        },
        /** ROAD */
        
        /** TRANSIT */
        {
            featureType: 'transit',
            elementType: 'all',
            styles: [{visibility: 'off'}]
        }
    ];

    darkStyle = [
        /** ALL */
        {elementType: 'geometry', stylers: [{color: '#081615'}]},
        {elementType: 'labels.text.fill', stylers: [{color: '#ffffff'}]},
        /** ADMINISTRATIVE */
        // Country
        {
            featureType: 'administrative.country',
            elementType: 'geometry',
            stylers: [{weight: '2'}, {color: '#006064'}]
        },
        {
            featureType: 'administrative.country',
            elementType: 'labels.text.fill',
            stylers: [{color: '#f2f2f2'}]
        },
        // Province
        {
            featureType: 'administrative.province',
            elementType: 'geometry',
            stylers: [{color: '#005b63'}, {weight: '3'}] 
        },
        {
            featureType: 'administrative.province',
            elementType: 'labels.text.fill',
            stylers: [{color: '#bfbfbf'}]
        },
        // Locality
        {
            featureType: 'administrative.locality',
            elementType: 'labels.text.fill',
            stylers: [{color: '#f0f0f0'}]
        },
        // Neighborhood
        {
            featureType: 'administrative.neighborhood',
            elementType: 'labels.text.fill',
            stylers: [{color: '#d8d8d8'}]
        },
        /** LANDSCAPE */
        {
            featureType: 'landscape',
            elementType: 'all',
            stylers: [{color: '#00838f'}]
        },
        /** ROAD */
        {
            featureType: 'road',
            elementType: 'geometry',
            stylers: [{color: '#006064'}]
        },
        {
            featureType: 'road',
            elementType: 'labels.text.fill',
            stylers: [{color: '#ffffff'}]
        },
    ];

    lightStyle = [
        /** ALL */
        {elementType: 'geometry', stylers: [{color: '#f5f8f8'}]},
        {elementType: 'labels.text.fill', stylers: [{color: '#000000'}]},
        /** ADMINISTRATIVE */
        // Country
        {
            featureType: 'administrative.country',
            elementType: 'geometry',
            stylers: [{weight: '2'}, {color: '#2979FF'}]
        },
        {
            featureType: 'administrative.country',
            elementType: 'labels.text.fill',
            stylers: [{color: '#1d1d1d'}]
        },
        // Province
        {
            featureType: 'administrative.province',
            elementType: 'geometry',
            stylers: [{color: '#1E88E5'}, {weight: '3'}] 
        },
        {
            featureType: 'administrative.province',
            elementType: 'labels.text.fill',
            stylers: [{color: '#2d2d2d'}]
        },
        // Locality
        {
            featureType: 'administrative.locality',
            elementType: 'labels.text.fill',
            stylers: [{color: '#000000'}]
        },
        // Neighborhood
        {
            featureType: 'administrative.neighborhood',
            elementType: 'labels.text.fill',
            stylers: [{color: '#f4f6f9'}]
        },
        /** LANDSCAPE */
        {
            featureType: 'landscape',
            elementType: 'all',
            stylers: [{color: '#82B1FF'}]
        },
        /** ROAD */
        {
            featureType: 'road',
            elementType: 'geometry',
            stylers: [{color: '#448AFF'}]
        }
    ];

    // Is mobile or not
    get mobile(): boolean {
        return this.$vuetify.breakpoint.smAndDown;
    }

    // Current theme
    get activeTheme(): Array<{}> {
		return (this.$vuetify.theme.dark) ? [...this.darkStyle, ...this.defaultStyle] : [...this.lightStyle, ...this.defaultStyle];
	}
}
